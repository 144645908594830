@kallithea-theme-main-color: #f7f7f7;
@kallithea-theme-inverse-color:     #888888;
@kallithea-theme-inverse-bg:        @kallithea-theme-main-color;
// Don't do this - it introduces horizontal scrollbars to every panel, looks a right mess...
//@kallithea-panel-margin:            20px;

@limegreen: #66ff88;

@kallithea-logo-url: "../images/limecode-logo-transparent.png";
@kallithea-logo-width:              200px;
@kallithea-logo-height:             40px;
@kallithea-logo-bottom:             0px;

@brand-primary: @kallithea-theme-inverse-color;
@link-hover-color:                  darken(@kallithea-theme-inverse-color, 20.0%);

/* logo */
nav.navbar.mainmenu > .navbar-header > .navbar-brand {
    font-size: 20px;
    padding-top: 12px;
    > .branding:before {
      content: "";
      display: inline-block;
      margin-right: .2em;
      background-image: url(@kallithea-logo-url);
      width: @kallithea-logo-width;
      height: @kallithea-logo-height;
      margin-bottom: -@kallithea-logo-bottom;
      margin-top: -8px;
    }
  }

div.panel-heading {
    background-color: @limegreen;
  }

